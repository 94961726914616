

<template>
    <Layout>
        <PageHeader :title="title" :items="[]" />
        <loading-vue v-if="load"></loading-vue>
    
        <div class="row g-2" v-else>
            <div class="col-md-6 mx-auto">
    
    
    
    
       <Notification />
    
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header align-items-center d-flex">
                                <h4 class="card-title mb-0 flex-grow-1">My Gopay Accounts</h4>
                         <div class="flex-shrink-0">
                            <b-button @click="goto" variant="primary" size="sm">Online Deposit</b-button>
                        </div>
                            </div>
                            <div class="card-body">
    
                                <div class="row">
                                    <div class="col-lg-3">
                                        <div class="nav nav-pills flex-column nav-pills-tab custom-verti-nav-pills text-center" role="tablist" aria-orientation="vertical">
                                            <a class="nav-link " :class="key == 0? 'active show' :''" data-bs-toggle="pill" v-for="(item, key) in items" :key="item.id" :id="'bank-'+item.id+'-tab'" :href="'#bank-'+item.id" role="tab" :aria-controls="'bank-'+item.id" :aria-selected="key == 0? 'true' :'false'" >
                                           
                                            {{item.bank_name}}</a>
    
                                        </div>
                                    </div>
                                    <!-- end col-->
                                      <div class="col-lg-9">
                                        <div class="tab-content text-muted mt-3 mt-lg-0">
                                            <div class="tab-pane fade " :class="key == 0? 'active show' :''" v-for="(item, key) in items" :key="item.id" :id="'bank-'+item.id" role="tabpanel" :aria-labelledby="'bank-'+item.id+'-tab'">
                                                <div class="mb-3">
                                                    <label for="employeeName" class="form-label">Account Number</label>
                                                  <h5 class="mb-3">{{item.account_number}}</h5>
                                                </div>
                                                <div class="mb-3">
                                                    <label for="employeeUrl" class="form-label">Account Name</label>
                                                        <h5 class="mb-3">{{item.account_name}}</h5>
                                                  
                                                </div>
                                                <div class="mb-3">
                                                    <label for="StartleaveDate" class="form-label">Bank Name</label>
                                                    <h5 class="mb-3">{{item.bank_name}}</h5>
                                                </div>
                                            </div>
    
                                            <!--end tab-pane-->
                                        </div>
                                    </div>
                                    <!-- end col-->
                                </div>
                                <!-- end row-->
                            </div>
                            <!-- end card-body -->
                        </div>
                    </div>
    
                </div>
            </div>
    
            <!-- end col -->
        </div>
        <!--end row-->
    
    
    </Layout>
</template>






<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import axios from 'axios';
import Swal from "sweetalert2";
import LoadingVue from "./components/Loading.vue";


export default {
    page: {
        title: "Bank Accounts",
        meta: [{ name: "description", content: appConfig.description }],
    },
    data() {
        return {
            link: process.env.VUE_APP_URL,
            title: "Bank Accounts",




            load: false,
            banks: [],
        };
    },
    name: "Widgets",
    components: {
        Layout,
        PageHeader,
        LoadingVue,

    },
    created() {
        this.getitems()
    },



    methods: {

    goto() {

    this.$router.push({name: 'deposit'});

    },

        async getitems() {
            this.load = true;
            await axios.get(process.env.VUE_APP_MAIN_URL + "/generated/account").catch((error) => {

                this.load = false;
                Swal.fire("Error!", error.response.data.data.data, "error");
                throw error;

            }).then((response) => {
                this.load = false;
                if (response.data.status == true) {

                    this.items = response.data.data.data;
                    console.log(this.items)


                }

            })
        },




         async gettransactions() {
            this.load = true;
            await axios.get(process.env.VUE_APP_MAIN_URL + "/generated/account").catch((error) => {

                this.load = false;
                Swal.fire("Error!", error.response.data.data.data, "error");
                throw error;

            }).then((response) => {
                this.load = false;
                if (response.data.status == true) {

                    this.items = response.data.data.data;
                    console.log(this.items)


                }

            })
        },
    },

};
</script>
